import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "container h-100 d-flex justify-content-center align-items-center" }, [
      _createElementVNode("div", { class: "row" }, [
        _createElementVNode("div", { class: "col-12 text-center" }, [
          _createElementVNode("h1", null, "404 Error - Page Not Found"),
          _createElementVNode("p", null, "We're sorry, but the page you requested could not be found. Please check the URL and try again.")
        ])
      ])
    ], -1)
  ])))
}