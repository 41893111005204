export const Settings = {
    settings: null as unknown | any,

    init(newSettings: unknown | any) {
        this.settings = newSettings;
    },

    get() {
        return this.settings;
    },
};
