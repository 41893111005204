class SignatureQueryParams {
    shop_id: string | null | undefined = null;
    language: string | null | undefined = null;
    timestamp: string | null | undefined = null;
    signature: string | null | undefined = null;

    constructor(response: Record<string, string | null | undefined>) {
        this.shop_id = response.shop_id;
        this.language = response.language;
        this.timestamp = response.timestamp;
        this.signature = response.signature;

        return this;
    }

    ToQueryString() {
        return `shop_id=${this.shop_id}&language=${this.language}&timestamp=${this.timestamp}&signature=${this.signature}`;
    }

    IsValid() {
        return this.shop_id != null && this.shop_id != undefined && this.language != null && this.language != undefined && this.timestamp != null && this.timestamp != undefined && this.signature != null && this.signature != undefined;
    }
}

export { SignatureQueryParams };
