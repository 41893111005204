class TextResourceList {
    textResourceItems = new Array<TextResourceItem>();
}

class TextResourceItem {
    sysName = "";
    value = "";
}

export { TextResourceList, TextResourceItem };
