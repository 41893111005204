import { getWebSite } from "@/utils/common";
import { CountryCode, LanguageCode } from "@/enums";

class MerchantSettings {
    languageCode = LanguageCode[LanguageCode.EN];
    redirectUrl = getWebSite();
    merchantSettingsApiKeyValues = new Array<MerchantSettingsApiKeyValue>();
    enableMinMaxAmountFilter = false;
    minAmountFilter = null as string | null;
    maxAmountFilter = null as string | null;
}

class MerchantSettingsApiKeyValue {
    country = CountryCode[CountryCode.NL];
    apiKey = "";

    init(countryCode: CountryCode) {
        this.country = CountryCode[countryCode];
        this.apiKey = "";

        return this;
    }
}

export { MerchantSettings, MerchantSettingsApiKeyValue };
