<style lang="scss" scoped src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="row">
        <div class="col-12 p-4 pb-0">
            <div class="row r-bg-white-haze-200">
                <div class="col-12 py-2">
                    <p v-html="getTranslation('Installments')"></p>
                </div>
                <div class="col-12">
                    <ul class="timeline">
                        <li v-for="(item, index) in payInX" v-bind:key="index" class="bullet">
                            <p class="pb-2">
                                <span class="r-type-body-text-s-semibold">
                                    {{ currency(item.dueAmount) }}
                                </span>
                                <br />
                                <span class="r-color-grey-charcoal-300" v-html="getTranslation('WithinDays', [getWithinDay(item.dueDate.toString())])"></span>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
