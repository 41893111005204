import { getWebSite, loading } from "@/utils/common";
import { getTextResources } from "tests/entities";
import { TextResourceList } from "@/entities";
import { Telemetry } from "@/utils/telemetry";
import { post } from "@/utils/httpRequest";
import { LanguageCode } from "@/enums";
import { defineComponent } from "vue";

export default defineComponent({
    name: "ErrorView",
    data: function () {
        return {
            language: LanguageCode[LanguageCode.EN],
            textResourceList: new TextResourceList(),
        };
    },
    mounted() {
        Telemetry.pageLoaded(`${this.$options.name}`);

        const urlParams = new URLSearchParams(window.location.search);
        const langParam = urlParams.get("lang");
        if (langParam) {
            this.language = langParam;
        }
        this.getData();
    },
    methods: {
        async getData() {
            loading();
            this.textResourceList = (await this.getTextResources(this.language)) as TextResourceList;
            loading();
        },
        handleReturnTo() {
            Telemetry.pageRedirected(`${this.$options.name}`);
            window.location.href = getWebSite();
        },
        async getTextResources(languageCode: string, agent: unknown | null = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = ["TechnicalErrorTitle", "TechnicalErrorDescription", "ReturnTo"];

            const response = await post(
                "text-resources/list",
                {
                    languageCode,
                    sysnameList,
                },
                agent,
            );

            if (agent != null) {
                return { obj: response, list: sysnameList };
            }

            return response;
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            let value = this.textResourceList?.textResourceItems.find((x) => x.sysName == sysName)?.value;

            replace.forEach((item: string, index: number) => {
                value = value?.replace(`{${index}}`, item);
            });

            return value ?? "";
        },
    },
});
