export enum RequiredField {
    TermsAndConditions,
    BirthDate,
    IbanNumber,
    InstallmentPlanNr,
    IdentificationNumber,
    CampaignNo,
    CompanyName,
    MobilePhone,
}
