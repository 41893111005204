import { getWebSite } from "@/utils/common";

class Client {
    company = new ClientCompany();
    merchant = new Merchant();
    language = "en";
    completeProcessLink = getWebSite();
    completeProcessLinkText = "";
    unableToProceed = {
        closeButton: () => {
            window.location.href = "#";
        },
    };

    constructor(response: Client) {
        this.company = response.company;
        this.merchant = response.merchant;
        this.language = response.language;
        this.unableToProceed = response.unableToProceed;
        this.completeProcessLink = response.completeProcessLink;

        return this;
    }

    toJson() {
        return JSON.stringify(this, function (key, value) {
            if (typeof value === "function") {
                return value.toString();
            } else {
                return value;
            }
        });
    }
}

class Merchant {
    firstName = "";
    lastName = "";
    phone = "";
    email = "";
}

class ClientCompany {
    companyName = "";
    companyUrl = "";
    ecommercePlatform = "Lightspeed";
}

export { Client, Merchant, ClientCompany };
