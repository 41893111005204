import { defineComponent } from "vue";

export default defineComponent({
    name: "ModalComponent",
    methods: {
        handleClick() {
            document.body.style.overflow = "auto";
        },
    },
    updated() {
        window.addEventListener("hashchange", () => {
            const modal = document.getElementById("modal-content");

            if (!modal) {
                return;
            }

            if (window.location.hash === "#modal-content") {
                modal.style.zIndex = "0";
            } else {
                modal.style.zIndex = "-1";
            }
        });
    },
});
