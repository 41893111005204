import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/exclamation_mark.svg'


const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row mt-lg-4 pt-lg-5" }
const _hoisted_4 = { class: "col-12 col-lg-10 max-width" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_r_button = _resolveComponent("r-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "row ps-lg-5 my-5" }, [
        _createElementVNode("div", { class: "col-auto ps-lg-5 ms-lg-5 mt-lg-3 mb-lg-5" }, [
          _createElementVNode("img", {
            class: "ps-lg-2",
            alt: "Riverty logo",
            width: "213",
            src: "https://cdn.riverty.design/logo/riverty-logo-green.svg"
          })
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-12 col-lg-2" }, [
          _createElementVNode("img", {
            alt: "Error icon",
            class: "float-lg-end",
            width: "58",
            height: "58",
            src: _imports_0
          })
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", {
            class: "r-font-family-heading r-font-size-700 r-font-weight-light my-3 my-lg-0",
            innerHTML: _ctx.getTranslation('TechnicalErrorTitle')
          }, null, 8, _hoisted_5),
          _createElementVNode("p", {
            class: "r-font-size-400 my-lg-3 mb-5 mb-lg-0",
            innerHTML: _ctx.getTranslation('TechnicalErrorDescription')
          }, null, 8, _hoisted_6),
          _createVNode(_component_r_button, {
            variant: "primary",
            class: "mt-lg-5",
            onClick: _ctx.handleReturnTo
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.getTranslation("ReturnTo", ["Riverty"])), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ])
  ]))
}