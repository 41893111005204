class RequiredFieldItem {
    requiredField: string;
    value: string;

    constructor(field: string) {
        this.requiredField = field;
        this.value = "";
    }
}

export { RequiredFieldItem };
