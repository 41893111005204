import { LanguageCode, OrderStatus, CountryCode, Provider, PaymentType } from "@/enums";

class OrderInformation {
    orderDetails = new OrderDetails();
    redirectUrl = "";
    legalInfoRequiredFieldsPerPayments = new Array<LegalInfoRequiredFieldsPerPayment>();
    profileTrackingId = "";
}

class OrderDetails {
    merchantName = "";
    billingCustomer = new Customer();
    shippingCustomer = new Customer();
    orderDetail = new OrderDetail();
    paymentMethod = new PaymentMethod();
    requiredFields = new Array<string>();
    language = LanguageCode[LanguageCode.NL];
    shippingGrossPrice = 0;
    shippingNetPrice = 0;
    orderStatus = OrderStatus[OrderStatus.New];
    provider = Provider[Provider.ECom];
    country = CountryCode[CountryCode.Undefined];
}

class OrderDetail {
    totalAmount = 0;
    items = new Array<OrderInformationItem>();
    currency = "";
}

class OrderInformationItem {
    name = "";
    description = "";
    grossUnitPrice = 0;
    quantity = 0;
}

class PaymentMethod {
    title = "";
    method = "";
    additionalData: string | null = null;
    legalInfo = new LegalInfo();
    type = "";
    isDirectDebit = false;
    bankAccount: string | null = null;
    campaignNumber: number | null = null;
}

class Customer {
    firstName = "";
    lastName = "";
    address = new Address();
}

class Address {
    street = "";
    streetNumber = "";
    postalCode = "";
    postalPlace = "";
    countryCode = "";
}

class LegalInfo {
    requiresCustomerConsent = false;
    termsAndConditionsUrl = "";
    privacyStatementUrl = "";
    codeOfConduct = "";
    bgb507Url = "";
    secciUrl = "";
    title = "";
    text = "";
    tag = "";
    logo = "";
    secondaryLogo = "";
}

class InstallmentInfo {
    basketAmount = 0;
    numberOfInstallments = 0;
    installmentAmount = 0;
    firstInstallmentAmount = 0;
    lastInstallmentAmount = 0;
    interestRate = 0;
    effectiveInterestRate = 0;
    effectiveAnnualPercentageRate = 0;
    totalInterestAmount = 0;
    startupFee = 0;
    monthlyFee = 0;
    totalAmount = 0;
    installmentProfileNumber = 0;
    readMore = "";
}

class LegalInfoRequiredFieldsPerPayment {
    type = PaymentType[PaymentType.Undefined];
    legalInfo = new LegalInfo();
    requiredFields = new Array<string>();
    isDirectDebit = false;
}

export { OrderInformation, OrderDetail, OrderInformationItem, PaymentMethod, Customer, Address, LegalInfo, InstallmentInfo, LegalInfoRequiredFieldsPerPayment };
