import { defineCustomElements } from "@riverty/web-components/loader";
import { Telemetry } from "./utils/telemetry";
import { Settings } from "./utils/settings";
import { vMaska } from "maska/vue";
import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";

const app = createApp(App);
app.use(router);
defineCustomElements(window);
app.directive("maska", vMaska);

fetch("/settings.json").then(async (response) => {
    const settings = await response?.json();

    Settings.init(settings);

    Telemetry.init();
    app.mount("#app");
});
