import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row px-2 px-sm-0" }
const _hoisted_4 = { class: "col-12 px-sm-0 py-4" }
const _hoisted_5 = { class: "r-font-family-heading r-font-size-600 r-font-weight-light mt-2" }
const _hoisted_6 = { class: "container" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-12 col-lg me-lg-3 order-2 order-lg-1" }
const _hoisted_9 = { class: "row bg-white px-2 py-4" }
const _hoisted_10 = { class: "col-12 mb-3" }
const _hoisted_11 = { class: "r-font-size-500 mb-3" }
const _hoisted_12 = { class: "r-font-weight-semibold" }
const _hoisted_13 = { class: "col-12 text-center text-lg-start" }
const _hoisted_14 = { class: "col-12 col-lg-4 order-1 order-lg-2" }
const _hoisted_15 = { class: "row bg-white px-2 py-4" }
const _hoisted_16 = { class: "col-12" }
const _hoisted_17 = { class: "r-font-size-600 r-font-weight-semibold mb-4" }
const _hoisted_18 = { class: "r-font-size-600 r-font-weight-semibold" }
const _hoisted_19 = { class: "col-12" }
const _hoisted_20 = { class: "row h-100 align-items-center" }
const _hoisted_21 = { class: "col" }
const _hoisted_22 = { class: "col-auto" }
const _hoisted_23 = {
  key: 0,
  class: "row mt-3"
}
const _hoisted_24 = { class: "col-12" }
const _hoisted_25 = { class: "r-font-weight-semibold" }
const _hoisted_26 = { class: "col-12 mt-3" }
const _hoisted_27 = { class: "r-font-weight-semibold" }
const _hoisted_28 = { class: "container" }
const _hoisted_29 = { class: "row px-2 px-sm-0" }
const _hoisted_30 = { class: "col-12 px-sm-0 pb-3 pt-5 mt-5 pt-lg-3 mt-lg-0" }
const _hoisted_31 = { class: "r-font-size-200" }
const _hoisted_32 = {
  key: 0,
  class: "container p-0"
}
const _hoisted_33 = { class: "row pb-3" }
const _hoisted_34 = { class: "col-12" }
const _hoisted_35 = { class: "r-font-family-heading r-font-size-600 r-font-weight-light mt-3 mb-4" }
const _hoisted_36 = ["innerHTML"]
const _hoisted_37 = { class: "col-12 text-center text-lg-start col-lg-auto" }
const _hoisted_38 = {
  key: 1,
  class: "container p-0"
}
const _hoisted_39 = { class: "row pb-3" }
const _hoisted_40 = { class: "col-12" }
const _hoisted_41 = { class: "r-font-family-heading r-font-size-600 r-font-weight-light my-4" }
const _hoisted_42 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_r_radio_button = _resolveComponent("r-radio-button")!
  const _component_r_button = _resolveComponent("r-button")!
  const _component_r_accordion_item = _resolveComponent("r-accordion-item")!
  const _component_r_accordion = _resolveComponent("r-accordion")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("header", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[0] || (_cache[0] = _createElementVNode("img", {
                class: "r-icon-size-l mb-2",
                alt: "Riverty logo",
                src: "https://cdn.riverty.design/logo/riverty-logomark-green.svg"
              }, null, -1)),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.getTranslation("CheckoutTitle")), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("main", null, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.getTranslation("SelectPaymentMethod")), 1)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.legalInfoRequiredFieldsPerPayments, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "mb-4"
                  }, [
                    _createVNode(_component_r_radio_button, {
                      value: item.legalInfo.title,
                      name: "paymentMethods",
                      onClick: ($event: any) => (_ctx.onChecked(item))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_12, _toDisplayString(item.legalInfo.title), 1),
                        _createElementVNode("p", null, _toDisplayString(item.legalInfo.tag), 1)
                      ]),
                      _: 2
                    }, 1032, ["value", "onClick"])
                  ]))
                }), 128)),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_r_button, {
                    variant: "text",
                    href: "#modal-content",
                    onClick: _ctx.handleCancel
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getTranslation("CancelPayment")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.data.orderDetails.merchantName), 1),
                  _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.currency(_ctx.data.orderDetails.orderDetail.totalAmount)), 1),
                  _createVNode(_component_r_accordion, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_r_accordion_item, {
                        header: _ctx.getTranslation('OrderDetailsTitle')
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.orderDetails.orderDetail.items, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "row",
                              key: index
                            }, [
                              _createElementVNode("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, [
                                  _createElementVNode("div", _hoisted_21, [
                                    _createElementVNode("p", null, _toDisplayString(item.quantity) + "x " + _toDisplayString(item.name), 1),
                                    _createElementVNode("p", null, _toDisplayString(item.description), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_22, [
                                    _createElementVNode("p", null, _toDisplayString(_ctx.currency(item.grossUnitPrice)), 1)
                                  ])
                                ]),
                                _cache[1] || (_cache[1] = _createElementVNode("hr", { class: "m-0 pb-4" }, null, -1))
                              ])
                            ]))
                          }), 128)),
                          (_ctx.showAddresses())
                            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                _createElementVNode("div", _hoisted_24, [
                                  _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.getTranslation("ShippingAddress")), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.shippingCustomer.firstName) + " " + _toDisplayString(_ctx.data.orderDetails.shippingCustomer.lastName), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.shippingCustomer.address.street) + " " + _toDisplayString(_ctx.data.orderDetails.shippingCustomer.address.streetNumber), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.shippingCustomer.address.postalCode) + " " + _toDisplayString(_ctx.data.orderDetails.shippingCustomer.address.countryCode), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.shippingCustomer.address.postalPlace), 1)
                                ]),
                                _createElementVNode("div", _hoisted_26, [
                                  _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.getTranslation("BillingAddress")), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.billingCustomer.firstName) + " " + _toDisplayString(_ctx.data.orderDetails.billingCustomer.lastName), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.billingCustomer.address.street) + " " + _toDisplayString(_ctx.data.orderDetails.billingCustomer.address.streetNumber), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.billingCustomer.address.postalCode) + " " + _toDisplayString(_ctx.data.orderDetails.billingCustomer.address.countryCode), 1),
                                  _createElementVNode("p", null, _toDisplayString(_ctx.data.orderDetails.billingCustomer.address.postalPlace), 1)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["header"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("footer", null, [
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.getTranslation("Powered")), 1),
              _cache[2] || (_cache[2] = _createElementVNode("img", {
                alt: "Riverty logo",
                width: "143",
                src: "https://cdn.riverty.design/logo/riverty-logo-green.svg"
              }, null, -1))
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_ModalComponent, null, {
      default: _withCtx(() => [
        (!_ctx.showErrorPaymentMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.getTranslation("TitleCancelation")), 1),
                  _createElementVNode("p", {
                    class: "r-font-size-400 mb-4 pb-3",
                    innerHTML: _ctx.getTranslation('DescriptionCancelation', [_ctx.data.orderDetails.merchantName])
                  }, null, 8, _hoisted_36)
                ]),
                _createElementVNode("div", _hoisted_37, [
                  _createVNode(_component_r_button, {
                    variant: "primary",
                    class: "w-100 mb-2",
                    onClick: _ctx.handleConfirmCancelation
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getTranslation("ConfirmCancelation")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                  _createVNode(_component_r_button, {
                    variant: "text",
                    onClick: _ctx.handleContinueWith
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getTranslation("ContinueWith")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_38, [
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("p", _hoisted_41, _toDisplayString(_ctx.getTranslation("PaymentCustomErrorTitle")), 1),
                  _createElementVNode("p", {
                    class: "r-font-size-400 mb-4 pb-3",
                    innerHTML: _ctx.getTranslation('PaymentCustomErrorSubTitle', [''])
                  }, null, 8, _hoisted_42),
                  _createVNode(_component_r_button, {
                    variant: "primary",
                    onClick: _ctx.handleContinueWith
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getTranslation("ReturnTo", [_ctx.data.orderDetails.merchantName ?? _ctx.getTranslation("GenericReturnToMerchant")])), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ])
            ]))
      ]),
      _: 1
    })
  ], 64))
}