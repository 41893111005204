import { NotFoundView, PayView, OnboardingView, ErrorView, SettingsView, PaymentMethodsView } from "@/views";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/pay/:language/:orderKey",
        name: "Hosted Checkout Service - Pay",
        component: PayView,
        props: true,
    },
    {
        path: "/payment-methods/:language/:orderKey",
        name: "Hosted Checkout Service - Payment methods",
        component: PaymentMethodsView,
        props: true,
    },
    {
        path: "/onboarding",
        name: "Hosted Checkout Service - Onboarding",
        component: OnboardingView,
    },
    {
        path: "/settings",
        name: "Hosted Checkout Service - Settings",
        component: SettingsView,
    },
    {
        path: "/error",
        name: "Hosted Checkout Service - Error",
        component: ErrorView,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "Page Not Found",
        component: NotFoundView,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, _, next) => {
    if (to.name) {
        document.title = to.name.toString();
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = to.name.toString();
        document.head.appendChild(meta);
    }
    next();
});

export default router;
