<style lang="scss" scoped src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="page">
        <div class="container">
            <div class="row pb-5">
                <div class="col-12 d-flex">
                    <img alt="Riverty logo" class="logo" src="https://cdn.riverty.design/logo/riverty-logo-green.svg" />
                    <p class="r-font-family-heading r-font-size-300 r-font-weight-light ps-3">{{ getTranslation("SettingsHeader") }}</p>
                </div>
            </div>
            <div class="row pb-5 pt-4" :hidden="!showMessage">
                <div class="col-12">
                    <div class="custom-alert d-flex justify-content-start align-items-center ps-3">
                        <strong class="pe-2">{{ getTranslation("SettingsSuccessMessage") }}</strong>
                        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.0601 1.81705L5.19928 11.8661L3.7142 10.1819L12.575 0.132812L14.0601 1.81705Z" fill="#527A42" />
                            <path d="M5.19165 11.8631L0 5.97524L1.48508 4.29101L6.67673 10.1789L5.19165 11.8631Z" fill="#527A42" />
                        </svg>
                    </div>
                </div>
            </div>
            <div class="row pb-5" :hidden="showMessage">
                <div class="col-12">
                    <p class="r-font-family-heading r-font-size-700 r-font-weight-light pb-3">{{ getTranslation("SettingsTitle") }}</p>
                    <p class="r-font-size-400">{{ getTranslation("SettingsDescription") }}</p>
                </div>
            </div>
            <div class="row pb-4">
                <div class="col-12 col-lg-6 pb-4 pb-lg-0">
                    <span class="pb-2"><img src="../../../public/flags/at.svg" class="pe-1 pb-1" />{{ getTranslation("SettingsInputTitle") }}</span>
                    <label class="r-font-size-200 w-100">
                        <input
                            class="r-input"
                            placeholder="e.g. 050C812VAB3445546BBD167"
                            inputmode="text"
                            :class="{ check: !hasChanded(countryCode.AT) && getApiKey(countryCode.AT).apiKey.length > 0, 'r-input-error': hasError(errorFieldEnum.NL) }"
                            :value="getApiKey(countryCode.AT).apiKey"
                            :onfocus="() => handleOnFocus(countryCode.AT)"
                            :oninput="(event: InputEvent) => handleOnInput(countryCode.AT, event)" />
                        <hint v-show="!hasError(errorFieldEnum.AT)" class="hint">{{ getTranslation("SettingsAustriaHint") }}</hint>
                        <hint v-show="hasError(errorFieldEnum.AT)" class="hint-error">{{ getTranslation("SettingsAustriaHintError") }}</hint>
                    </label>
                </div>
                <div class="col-12 col-lg-6">
                    <span class="pb-2"><img src="../../../public/flags/be.svg" class="pe-1 pb-1" />{{ getTranslation("SettingsInputTitle") }}</span>
                    <label class="r-font-size-200 w-100">
                        <input
                            class="r-input"
                            placeholder="e.g. 050C812VAB3445546BBD167"
                            inputmode="text"
                            :class="{ check: !hasChanded(countryCode.BE) && getApiKey(countryCode.BE).apiKey.length > 0, 'r-input-error': hasError(errorFieldEnum.NL) }"
                            :value="getApiKey(countryCode.BE).apiKey"
                            :onfocus="() => handleOnFocus(countryCode.BE)"
                            :oninput="(event: InputEvent) => handleOnInput(countryCode.BE, event)" />
                        <hint v-show="!hasError(errorFieldEnum.BE)" class="hint">{{ getTranslation("SettingsBelgiumHint") }}</hint>
                        <hint v-show="hasError(errorFieldEnum.BE)" class="hint-error">{{ getTranslation("SettingsBelgiumHintError") }}</hint>
                    </label>
                </div>
            </div>
            <div class="row pb-4">
                <div class="col-12 col-lg-6 pb-4 pb-lg-0">
                    <span class="pb-2"><img src="../../../public/flags/de.svg" class="pe-1 pb-1" />{{ getTranslation("SettingsInputTitle") }}</span>
                    <label class="r-font-size-200 w-100">
                        <input
                            class="r-input"
                            placeholder="e.g. 050C812VAB3445546BBD167"
                            inputmode="text"
                            :class="{ check: !hasChanded(countryCode.DE) && getApiKey(countryCode.DE).apiKey.length > 0, 'r-input-error': hasError(errorFieldEnum.NL) }"
                            :value="getApiKey(countryCode.DE).apiKey"
                            :onfocus="() => handleOnFocus(countryCode.DE)"
                            :oninput="(event: InputEvent) => handleOnInput(countryCode.DE, event)" />
                        <hint v-show="!hasError(errorFieldEnum.DE)" class="hint">{{ getTranslation("SettingsGermanHint") }}</hint>
                        <hint v-show="hasError(errorFieldEnum.DE)" class="hint-error">{{ getTranslation("SettingsGermanHintError") }}</hint>
                    </label>
                </div>
                <div class="col-12 col-lg-6">
                    <span class="pb-2"><img src="../../../public/flags/nl.svg" class="pe-1 pb-1" />{{ getTranslation("SettingsInputTitle") }}</span>
                    <label class="r-font-size-200 w-100">
                        <input
                            class="r-input"
                            placeholder="e.g. 050C812VAB3445546BBD167"
                            inputmode="text"
                            :class="{ check: !hasChanded(countryCode.NL) && getApiKey(countryCode.NL).apiKey.length > 0, 'r-input-error': hasError(errorFieldEnum.NL) }"
                            :value="getApiKey(countryCode.NL).apiKey"
                            :onfocus="() => handleOnFocus(countryCode.NL)"
                            :oninput="(event: InputEvent) => handleOnInput(countryCode.NL, event)" />
                        <hint v-show="!hasError(errorFieldEnum.NL)" class="hint">{{ getTranslation("SettingsNetherlandsHint") }}</hint>
                        <hint v-show="hasError(errorFieldEnum.NL)" class="hint-error">{{ getTranslation("SettingsNetherlandsHintError") }}</hint>
                    </label>
                </div>
            </div>
            <div class="row py-4">
                <div class="col-12 col-lg-6">
                    <label class="checkbox">
                        <input class="checkbox-input" type="checkbox" :checked="merchantSettings.enableMinMaxAmountFilter" v-on:change="handleCheckboxChange" />
                        {{ getTranslation("SettingsMinMaxAmountFilterTitle") }}
                    </label>
                    <p class="hint">{{ getTranslation("SettingsMinMaxAmountFilterHint") }}</p>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-12 col-lg-6 pb-4 pb-lg-0">
                    <span class="mb-2">{{ getTranslation("SettingsMinAmountFilterLabel") }}</span>
                    <label class="r-font-size-200 w-100">
                        <input
                            class="r-input"
                            :class="{
                                'r-input-error': hasError(errorFieldEnum.MinFilter),
                            }"
                            inputmode="numeric"
                            placeholder="150,00"
                            v-maska="minAmountFilterMaskaOptions"
                            data-maska="9 99#,##"
                            data-maska-tokens="9:[0-9]:repeated"
                            data-maska-reversed
                            :value="merchantSettings.minAmountFilter"
                            :disabled="!merchantSettings.enableMinMaxAmountFilter" />
                        <hint v-show="!hasError(errorFieldEnum.MinFilter)" class="hint">{{ getTranslation("SettingsMinAmountFilterHint") }}</hint>
                        <hint v-show="hasError(errorFieldEnum.MinFilter)" class="hint-error">{{ getTranslation("SettingsMinAmountFilterHintError") }}</hint>
                    </label>
                </div>
                <div class="col-12 col-lg-6">
                    <span class="mb-2">{{ getTranslation("SettingsMaxAmountFilterLabel") }}</span>
                    <label class="r-font-size-200 w-100">
                        <input
                            class="r-input"
                            :class="{
                                'r-input-error': hasError(errorFieldEnum.MaxFilter),
                            }"
                            inputmode="numeric"
                            placeholder="300,00"
                            v-maska="maxAmountFilterMaskaOptions"
                            data-maska="9 99#,##"
                            data-maska-tokens="9:[0-9]:repeated"
                            data-maska-reversed
                            :value="merchantSettings.maxAmountFilter"
                            :disabled="!merchantSettings.enableMinMaxAmountFilter" />
                        <hint v-show="!hasError(errorFieldEnum.MaxFilter)" class="hint">{{ getTranslation("SettingsMaxAmountFilterHint") }}</hint>
                        <hint v-show="hasError(errorFieldEnum.MaxFilter)" class="hint-error">{{ getTranslation("SettingsMaxAmountFilterHintError") }}</hint>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-12 pb-4">
                    <r-button variant="primary" :onclick="handleSubmit" :disabled="changedFields.length == 0 && !elementUpdated">{{ getTranslation("SettingsSave") }}</r-button>
                </div>
                <div class="col-12">
                    <r-button variant="text" :onclick="handleClose">{{ getTranslation("SettingsClose") }}</r-button>
                </div>
            </div>
        </div>
    </div>
</template>
