export enum LanguageCode {
    Undefined,
    NO = 1,
    SV = 2,
    FI = 3,
    DA = 4,
    EN = 5,
    DE = 6,
    NL = 7,
    FR = 8,
}
