class ProfileTrackingIdConfig {
    // static alphanumerical
    c = "";
    // unique dynamic value for identifying a web session. The string can have a maximum length of 64 characters.
    s = "";
    // current page path/URL
    u = "";
    // subdomain assigned by the client, if there is no option to create a subdomain, please contact implementation_support@riverty.comm
    h = "whm.asip.cloud";

    constructor(c: string, s: string, u: string) {
        this.c = c;
        this.s = s;
        this.u = u;
    }
}

export { ProfileTrackingIdConfig };
