import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 p-4 pb-0" }
const _hoisted_3 = { class: "row r-bg-white-haze-200" }
const _hoisted_4 = { class: "col-12 py-2" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "timeline" }
const _hoisted_8 = { class: "pb-2" }
const _hoisted_9 = { class: "r-type-body-text-s-semibold" }
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", {
            innerHTML: _ctx.getTranslation('Installments')
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("ul", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payInX, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "bullet"
              }, [
                _createElementVNode("p", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.currency(item.dueAmount)), 1),
                  _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("span", {
                    class: "r-color-grey-charcoal-300",
                    innerHTML: _ctx.getTranslation('WithinDays', [_ctx.getWithinDay(item.dueDate.toString())])
                  }, null, 8, _hoisted_10)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}