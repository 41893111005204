import { PayInX } from "@/entities";
import { currency } from "@/utils/common";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "PayInXComponent",
    props: {
        payInX: {
            type: Array as PropType<PayInX[]>,
            required: true,
        },
        getTranslation: {
            type: Function,
            required: true,
        },
    },
    methods: {
        currency,
        getWithinDay(value: string): number {
            const date = new Date(value).getTime();
            const timeDiff = Math.abs(Date.now() - date);
            const day = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

            return day;
        },
    },
});
