import { Settings } from "./settings";
import { getCookie } from "./common";

const CHECKOUT_FLOW = "/pay/";
const PAYMENT_METHODS_FLOW = "/payment-methods/";
const PAGE_TO_USE_HEADERS_VALIDATION = [CHECKOUT_FLOW, PAYMENT_METHODS_FLOW];

async function base(path: string, requestOptions: RequestInit) {
    let headers = requestOptions.headers;

    if (!(headers instanceof Headers)) {
        headers = new Headers(headers);
    }

    if (PAGE_TO_USE_HEADERS_VALIDATION.some((page) => window.location.href.includes(page))) {
        headers.append("token", getCookie("token"));
    }

    requestOptions.headers = headers;
    const response: Response = await fetch(`${Settings.get().VUE_APP_API_URL}/${path}`, requestOptions);
    try {
        const data = await response?.json();
        if (response.status == 401) {
            return (window.location.href = data.urlRedirect);
        }
        data.status = response.status;

        return data;
    } catch (error) {
        return response;
    }
}

async function get(path: string, agent: unknown | null = null) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        agent,
        credentials: "include",
    } as RequestInit;

    return base(path, requestOptions);
}

async function post(path: string, body: unknown, agent: unknown | null = null) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
        },
        agent,
        credentials: "include",
    } as RequestInit;

    return base(path, requestOptions);
}

async function put(path: string, body: unknown = null, agent: unknown | null = null) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
        },
        agent,
        credentials: "include",
    } as RequestInit;

    return base(path, requestOptions);
}

export { get, post, put };
