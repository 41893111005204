<style lang="scss" scoped src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div class="page">
        <ModalComponent>
            <div class="container p-0">
                <div class="row pb-4">
                    <div class="col-12 mt-1 mb-3">
                        <img alt="Riverty logo" width="143" src="https://cdn.riverty.design/logo/riverty-logo-green.svg" />
                    </div>
                    <div class="col-12 mb-4">
                        <p class="r-font-family-heading r-font-size-500 r-font-weight-light my-4">{{ getTranslation("PopupOnboardTitle") }}</p>
                        <p class="r-font-size-400 mb-4">{{ getTranslation("PopupOnboardDescription") }}</p>
                        <r-button variant="primary" v-on:click="handleGoOnboard">{{ getTranslation("PopupOnboard") }}</r-button>
                    </div>
                    <div class="col-12">
                        <p class="r-font-family-heading r-font-size-500 r-font-weight-light my-4">{{ getTranslation("PopupSettingsTitle") }}</p>
                        <p class="r-font-size-400 mb-4">{{ getTranslation("PopupSettingsDescription") }}</p>
                        <r-button variant="text" v-on:click="handleGoSettings">{{ getTranslation("PopupSettings") }}</r-button>
                    </div>
                </div>
            </div>
        </ModalComponent>
    </div>
</template>
