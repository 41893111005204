import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 p-4 pb-0" }
const _hoisted_3 = { class: "row mb-3" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "r-font-weight-semibold" }
const _hoisted_6 = { class: "row pb-1" }
const _hoisted_7 = { class: "col-12 py-2 r-bg-white-haze-200" }
const _hoisted_8 = { class: "row align-items-center mb-2" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "col-auto" }
const _hoisted_11 = { class: "row align-items-center mb-2" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "col-auto" }
const _hoisted_14 = { class: "row align-items-center pb-4" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { class: "col-auto" }
const _hoisted_17 = { class: "row align-items-center mb-2" }
const _hoisted_18 = { class: "col" }
const _hoisted_19 = { class: "col-auto" }
const _hoisted_20 = { class: "row align-items-center pb-4" }
const _hoisted_21 = { class: "col" }
const _hoisted_22 = { class: "r-font-weight-semibold" }
const _hoisted_23 = { class: "col-auto" }
const _hoisted_24 = { class: "r-font-weight-semibold" }
const _hoisted_25 = { class: "mb-4" }
const _hoisted_26 = { class: "mb-4" }
const _hoisted_27 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_r_radio_button = _resolveComponent("r-radio-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.installments, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "pb-2",
              key: index
            }, [
              _createVNode(_component_r_radio_button, {
                value: item.installmentProfileNumber,
                name: "installmentRadios",
                checked: item == _ctx.selectedInstallmentProfile,
                onClick: ($event: any) => (_ctx.onChecked(item))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.getTranslation("PayInInstallments", [item.numberOfInstallments, _ctx.currency(item.installmentAmount)])), 1)
                ]),
                _: 2
              }, 1032, ["value", "checked", "onClick"])
            ]))
          }), 128))
        ])
      ]),
      _createVNode(_Transition, null, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.getTranslation("OrderAmount")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.currency(_ctx.selectedInstallmentProfile?.basketAmount ?? 0)), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.getTranslation("InterestPA", [_ctx.selectedInstallmentProfile?.interestRate])), 1)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.currency(_ctx.selectedInstallmentProfile?.totalInterestAmount ?? 0)), 1)
                  ])
                ]),
                _cache[0] || (_cache[0] = _createElementVNode("hr", { class: "m-0" }, null, -1)),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.getTranslation("TotalAmountDue")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.currency(_ctx.selectedInstallmentProfile?.totalAmount ?? 0)), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.getTranslation("NumberOfInstallments")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.selectedInstallmentProfile?.numberOfInstallments), 1)
                  ])
                ]),
                _cache[1] || (_cache[1] = _createElementVNode("hr", { class: "m-0" }, null, -1)),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.getTranslation("InstallmentAmount")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.currency(_ctx.selectedInstallmentProfile?.installmentAmount ?? 0)), 1)
                  ])
                ]),
                _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.getTranslation("InterestInformation", [_ctx.selectedInstallmentProfile?.interestRate, _ctx.selectedInstallmentProfile?.effectiveAnnualPercentageRate])), 1),
                _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.getTranslation("LastInstallmentAmountDue", [_ctx.currency(_ctx.selectedInstallmentProfile?.lastInstallmentAmount ?? 0)])), 1),
                _withDirectives(_createElementVNode("p", {
                  class: "mb-4",
                  innerHTML: _ctx.getTranslation('MandatoryInformationLinks', [_ctx.paymentMethod.legalInfo.bgb507Url, _ctx.paymentMethod.legalInfo.secciUrl])
                }, null, 8, _hoisted_27), [
                  [_vShow, _ctx.paymentMethod.legalInfo.bgb507Url && _ctx.paymentMethod.legalInfo.secciUrl]
                ])
              ])
            ])
          ], 512), [
            [_vShow, _ctx.selectedInstallmentProfile]
          ])
        ]),
        _: 1
      })
    ])
  ]))
}