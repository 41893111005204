<style lang="scss" scoped src="./style.scss" />
<script lang="ts" src="./script.ts" />

<template>
    <div v-on:click="handleClick" id="modal-content">
        <div class="modal-inner">
            <slot></slot>
        </div>
    </div>
</template>
