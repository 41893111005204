import { InstallmentInfo, TextResourceList, PaymentMethod } from "@/entities";
import { currency, normalizeJsonToLowerCase } from "@/utils/common";
import { defineComponent } from "vue";

export default defineComponent({
    name: "FixedInstallmentsComponent",
    props: {
        paymentMethod: {
            type: PaymentMethod,
            required: true,
        },
        getTranslation: {
            type: Function,
            required: true,
        },
    },
    data: function () {
        return {
            installments: [] as InstallmentInfo[],
            textResourceList: new TextResourceList(),
            selectedInstallmentProfile: null as InstallmentInfo | null,
        };
    },
    mounted() {
        if (this.paymentMethod.additionalData) {
            this.installments = JSON.parse(normalizeJsonToLowerCase(this.paymentMethod.additionalData));
            //by default, the installment profile with least nr of installments should be selected
            this.selectedInstallmentProfile = this.installments.reduce((prev, curr) => {
                if (prev.numberOfInstallments < curr.numberOfInstallments) return prev;
                else if (prev.numberOfInstallments > curr.numberOfInstallments) return curr;
                else {
                    return this.installments.reduce((a, b) => (a.installmentProfileNumber > b.installmentProfileNumber ? a : b));
                }
            });
        }
    },
    methods: {
        currency,
        onChecked(item: InstallmentInfo) {
            this.selectedInstallmentProfile = item;
        },
    },
});
