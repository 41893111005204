import { TextResourceItem } from "@/entities";
import { jwtDecode, JwtPayload } from "jwt-decode";

type JsonObject = { [key: string]: unknown };

interface ImportObj {
    url: string;
    isModule: boolean;
}

interface JwtPayloadExtended extends JwtPayload {
    webSite: string;
}

function dynamicImport(item: ImportObj) {
    const rivertyOnboardingModule = document.createElement("script");
    rivertyOnboardingModule.setAttribute("src", item.url);

    if (item.isModule) rivertyOnboardingModule.setAttribute("type", "module");
    else rivertyOnboardingModule.setAttribute("nomodule", "");

    document.head.appendChild(rivertyOnboardingModule);

    return rivertyOnboardingModule;
}

function translation(list: Array<TextResourceItem>, sysName: string, replace: string[] = []): string {
    let value = list.find((x) => x.sysName == sysName)?.value;

    replace.forEach((item: string, index: number) => {
        value = value?.replace(`{${index}}`, item);
    });

    return value ?? "";
}

function currency(value: number, currency = "EUR", location = "en-US") {
    if (typeof value !== "number") {
        return value;
    }
    const formatter = new Intl.NumberFormat(location, {
        style: "currency",
        currency: currency,
    });
    return formatter.format(value);
}

function loading() {
    const progressBar = document.querySelector("#progress-bar") as HTMLDivElement;
    progressBar.style.display = progressBar.style.display == "flex" ? "none" : "flex";
}

function normalizeJsonToLowerCase(jsonString: string): string {
    const jsonData: JsonObject[] = JSON.parse(jsonString);
    const normalizedData: JsonObject[] = [];

    for (const item of jsonData) {
        const normalizedItem: JsonObject = {};

        for (const key in item) {
            const normalizedKey = key.charAt(0).toLowerCase() + key.slice(1);
            normalizedItem[normalizedKey] = item[key];
        }

        normalizedData.push(normalizedItem);
    }

    return JSON.stringify(normalizedData);
}

function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop()?.split(";").shift() ?? "";
    }
    return "";
}

function setCookie(name: string, value: string) {
    try {
        const newExp = jwtDecode(value).exp ?? 0;
        let oldExp = 0;

        const fromCookie = getCookie(name);
        if (fromCookie.length > 0) {
            oldExp = jwtDecode(fromCookie).exp ?? 0;
        }

        if (newExp > oldExp) {
            const expiryDate = newExp;
            document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expiryDate}; Secure;`;
        }

        const cookie = jwtDecode(document.cookie);
        sessionStorage.setItem("webSite", (<JwtPayloadExtended>cookie).webSite);
    } catch (_) {
        return false;
    }
}

function normalizeText(input: string) {
    return decodeURIComponent(escapeString(input));
}

function escapeString(input: string) {
    let escaped = encodeURIComponent(input).replace(/[!'()*]/g, (c) => "%" + c.charCodeAt(0).toString(16));

    const regexReplacements: [RegExp, string][] = [
        [/%2F/g, "/"],
        [/%83%C2/g, ""],
    ];

    regexReplacements.forEach(([pattern, replacement]) => {
        escaped = escaped.replace(pattern, replacement);
    });

    return escaped;
}

function getWebSite(): string {
    return sessionStorage.getItem("webSite") ?? "https://www.riverty.com/";
}

async function checkFetch(url: string) {
    if (url == null || url == "" || url == undefined) return false;
    try {
        return (await fetch(url, { method: "HEAD" })).ok;
    } catch (error) {
        return false;
    }
}

function validatedAndParseToFloat(value: string | null) {
    if (value == null) {
        return null;
    }
    return parseFloat(value.replace(/\s+/g, "").replace(",", "."));
}

function formatCurrency(value: string | number | null) {
    if (typeof value === "string") {
        const val = validatedAndParseToFloat(value);
        if (val == null) {
            return "";
        }
    } else if (value == null) {
        return "";
    }
    return (value as number).toLocaleString(navigator.language, { minimumFractionDigits: 2 }).replace(".", ",");
}

export { ImportObj, dynamicImport, translation, currency, loading, normalizeJsonToLowerCase, getCookie, setCookie, normalizeText, getWebSite, checkFetch, validatedAndParseToFloat, formatCurrency };
